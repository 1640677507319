// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faFacebookF  as fabFacebookF  } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faFlickr  as fabFlickr  } from "@fortawesome/free-brands-svg-icons/faFlickr";
import { faTwitter as fabTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faVimeoV  as fabVimeoV  } from "@fortawesome/free-brands-svg-icons/faVimeoV";
import { faYoutube  as fabYoutube  } from "@fortawesome/free-brands-svg-icons/faYoutube";

import { faBars as falFaBars } from "@fortawesome/pro-light-svg-icons/faBars";

import { faAngleDown      as farFaAngleDown      } from "@fortawesome/pro-regular-svg-icons/faAngleDown";
import { faClock          as farFaClock          } from "@fortawesome/pro-regular-svg-icons/faClock";
import { faFolder         as farFaFolder         } from "@fortawesome/pro-regular-svg-icons/faFolder";
import { faMapMarkerAlt   as farFaMapMarkerAlt   } from "@fortawesome/pro-regular-svg-icons/faMapMarkerAlt";
import { faMoneyBill      as farFaMoneyBill      } from "@fortawesome/pro-regular-svg-icons/faMoneyBill";
import { faMousePointer   as farFaMousePointer   } from "@fortawesome/pro-regular-svg-icons/faMousePointer";
import { faPhone          as farFaPhone          } from "@fortawesome/pro-regular-svg-icons/faPhone";
import { faQuestionCircle as farFaQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faSearch         as farFaSearch         } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTag            as farFaTag            } from "@fortawesome/pro-regular-svg-icons/faTag";
import { faUserCircle     as farFaUserCircle     } from "@fortawesome/pro-regular-svg-icons/faUserCircle";

import { faCaretDown    as fasFaCaretDown    } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faCaretLeft    as fasFaCaretLeft    } from "@fortawesome/pro-solid-svg-icons/faCaretLeft";
import { faCaretRight   as fasFaCaretRight   } from "@fortawesome/pro-solid-svg-icons/faCaretRight";
import { faCircle       as fasFaCircle       } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faFolder       as fasFaFolder       } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faSearch       as fasFaSearch       } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag          as fasFaTag          } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faUserCircle   as fasFaUserCircle   } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

/**
 * Add brand icons
 */
library.add(fabFacebookF, fabFlickr, fabTwitter, fabVimeoV, fabYoutube);

/**
 * Add light icons
 */
library.add(falFaBars);

/**
 * Add regular icons
 */
library.add(farFaAngleDown, farFaClock, farFaFolder, farFaMapMarkerAlt, farFaMoneyBill, farFaMousePointer, farFaPhone, farFaQuestionCircle, farFaSearch, farFaTag, farFaUserCircle);

/**
 * Add solid icons
 */
library.add(fasFaCaretDown, fasFaCaretLeft, fasFaCaretRight, fasFaCircle, fasFaFolder, fasFaSearch, fasFaTag, fasFaUserCircle);

/**
 * Add custom icons
 */
library.add({
    "flickr-dots": { // icon name
        iconName: "flickr-dots", // icon name
        prefix: "fac", // icon prefix
        icon: [
            448, // viewbox x
            512, // viewbox y
            [],  // ligature (unused)
            "e000", // unicode character
            "M99.469,354.969C44.487,354.969 0,310.482 0,255.5C0,200.518 44.487,156.031 99.469,156.031C154.45,156.031 198.937,200.518 198.937,255.5C198.937,310.482 154.45,354.969 99.469,354.969ZM348.531,354.969C293.55,354.969 249.063,310.482 249.063,255.5C249.063,200.518 293.55,156.031 348.531,156.031C403.513,156.031 448,200.518 448,255.5C448,310.482 403.513,354.969 348.531,354.969Z", // path
        ]
    },
});

/**
 * Watch the DOM to insert icons
 */
dom.watch();
